import React from 'react'
import { Link } from 'gatsby'
import { FirebaseContext } from 'gatsby-plugin-firebase'
import { useSession } from '../util/context.js'
import Spinner from '../components/spinner.js'
import MyCourseDropdown from '../components/my-course-dropdown.js'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import Img from 'gatsby-image'

const MyCourses = props => {
    const fb = React.useContext(FirebaseContext)
    const { user } = useSession()
    const [loading, setLoading] = React.useState(true)
    const [userCourses, setUserCourses] = React.useState(null)

    const { data, meta } = props

    React.useEffect(() => {
        if (!fb) return
        if (!user) {
            setLoading(false)
            return
        }

        const getUserCourses = async () => {
            const resp = await fb
                .firestore()
                .collection('users')
                .doc(user.uid)
                .collection('courses')
                .get()
            const purchasedCourses = []
            resp.forEach(doc => {
                let item = {
                    id: doc.id,
                    ...doc.data(),
                }

                data.allCourse.nodes.forEach(c => {
                    if (c.id === doc.id) {
                        item = { ...item, ...c }
                    }
                })
                purchasedCourses.push(item)
            })

            console.log(purchasedCourses)
            setUserCourses(purchasedCourses)
            setLoading(false)
        }
        getUserCourses()
    }, [fb, user])

    return (
        <div className="">
            <div className="max-w-3xl mx-auto bg-white rounded-lg shadow">
                <div className="py-6 px-4 sm:px-6 border-b-2 border-gray-200">
                    <h2 className="flex justify-between items-center text-lg font-medium leading-tight text-gray-900">
                        <span>My Courses</span>
                        <span className="inline-block relative h-8 w-8">
                            <Spinner loading={loading} />
                        </span>
                    </h2>
                </div>
                <ul className="flex flex-col items-center">
                    {userCourses && userCourses.length === 0 && (
                        <li className="py-8 flex flex-col items-center justify-center">
                            <div className="flex items-center">
                                <div className="w-14 h-14 mr-2 flex-center rounded-full bg-gray-100">
                                    <svg
                                        className="w-6 h-6 text-gray-500"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
                                        />
                                    </svg>
                                </div>
                                <span className="text-xl text-gray-500 font-medium">
                                    No courses found
                                </span>
                            </div>
                            <Link
                                to="/courses"
                                className="mt-8 mb-2 group flex justify-center sm:inline-flex items-center text-center border sm:px-0 py-3 bg-gray-800 text-white text-sm font-semibold rounded-md shadow-md  hover:bg-gray-700 focus:bg-gray-900 focus:outline-none focus:shadow-outline sm:text-sm sm:leading-5">
                                <span className="mr-4 sm:ml-12">Browse Course Catalog</span>
                                <svg
                                    className="transform duration-150 group-hover:translate-x-2 w-5 h-5 sm:mr-12"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M14 5l7 7m0 0l-7 7m7-7H3"
                                    />
                                </svg>
                            </Link>
                        </li>
                    )}
                    {userCourses &&
                        userCourses.map(course => (
                            <li
                                key={course.url}
                                className="w-full py-2 flex flex-col justify-center border-b hover:shadow ">
                                <div className="inline-flex px-6 py-4 w-full">
                                    <Link
                                        to={course.url}
                                        className="hidden sm:flex items-center hover:shadow-lg">
                                        <Img
                                            className="rounded shadow"
                                            fixed={course.cardImg.childImageSharp.fixed}
                                            alt={course.title}
                                        />
                                    </Link>
                                    <div className="flex-1 ml-3">
                                        <Link to={course.url} className="hover:underline">
                                            <h2 className="inline text-xl font-medium text-teal-700 hover:text-teal-900">
                                                {course.title}
                                            </h2>
                                        </Link>
                                        <p className="py-2 text-sm flex items-center">
                                            <svg
                                                className="w-5 h-5 text-gray-500 mr-1"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor">
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                                                />
                                            </svg>
                                            <span>Purchased on {course.purchasedAt}</span>
                                        </p>
                                    </div>
                                    <MyCourseDropdown {...course} />
                                </div>
                            </li>
                        ))}
                </ul>
            </div>
            <div className="max-w-3xl mx-auto flex sm:justify-end items-center">
                <OutboundLink
                    target="_blank"
                    rel="noopener"
                    href={meta.discordInvite}
                    className="py-2 flex-center text-sm leading-5 hover:underline text-gray-700 hover:text-gray-900 focus:outline-none focus:text-gray-900">
                    <span className="flex-center h-5 w-5">
                        <svg
                            className="h-5 w-5 text-gray-400"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                        </svg>
                    </span>
                    <span className="ml-1">Support</span>
                </OutboundLink>
            </div>
        </div>
    )
}

export default MyCourses
