import React from "react";
import { useOnClickOutside } from "../hooks/on-click-outside.js";
import { Link } from "gatsby";
import constants from "../constants";

export default (props) => {
  const { title, url, purchasedAt, id } = props;
  const [open, setOpen] = React.useState(false);

  const ref = React.useRef();
  useOnClickOutside(ref, () => setOpen(false));

  const today = new Date();
  const boughtAt = new Date(purchasedAt);
  const daysElapsed =
    (today.getTime() - boughtAt.getTime()) / (1000 * 3600 * 24);
  const disabledRefunds = daysElapsed > 14;

  return (
    <div ref={ref} className="relative">
      <div>
        <span className="rounded-md">
          <button
            aria-label="Toggle Dropdown"
            onClick={() => setOpen(!open)}
            type="button"
            className="inline-flex items-center justify-center rounded-full h-10 w-10 overflow-hidden hover:bg-gray-100 text-gray-500 hover:text-gray-900 focus:outline-none focus:border-gray-300 focus:shadow-outline active:text-gray-900 transition ease-in-out duration-150"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
              />
            </svg>
          </button>
        </span>
      </div>

      {open && (
        <div className="origin-top-right absolute right-0 mt-1 w-56 rounded-md shadow-lg">
          <div className="rounded-md bg-white shadow-xs">
            <div className="py-0">
              <Link
                to={url}
                className="block relative w-full text-left px-4 py-3 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
              >
                <span className="absolute left-0 inset-y pl-3">
                  <svg
                    className="h-5 w-5 text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z"
                    />
                  </svg>
                </span>
                <span className="pl-6">Go To Course</span>
              </Link>
            </div>
            <div className="border-t border-gray-100" />
            <div className="py-0 group">
              <Link
                disabled={disabledRefunds}
                to={`/refunds?from=/app/my-courses&title=${title}&id=${id}`}
                className={`block px-4 py-3 text-sm leading-5 ${
                  disabledRefunds
                    ? "pointer-events-none text-gray-300 cursor-not-allowed"
                    : "text-red-700 hover:text-red-900 hover:bg-gray-100 "
                } focus:outline-none focus:bg-gray-100 focus:text-gray-900`}
              >
                <span className="absolute left-0 inset-y pl-3">
                  <svg
                    className={`h-5 w-5 ${
                      disabledRefunds ? "text-gray-300" : "text-red-400"
                    }`}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                    />
                  </svg>
                </span>
                <span className="pl-6">Request Refund</span>
              </Link>
              {disabledRefunds && (
                <span className="hidden group-hover:inline cursor-default text-xs absolute left-0 mt-2 rounded px-4 py-2 z-10 text-gray-100 bg-gray-800">
                  This course is outside the refund period of 14 days. However
                  if you have a valid case email me at
                  <strong className="font-semibold"> {constants.EMAIL}</strong>
                </span>
              )}
            </div>
            <div className="border-t border-gray-100" />
          </div>
        </div>
      )}
    </div>
  );
};
