import React from 'react'
import { Router } from '@reach/router'
import Layout from '../components/layout'
import MyCourses from '../components/my-courses'
import PrivateRoute from '../components/private-route.js'
import queryString from 'query-string'
import { useSiteMetadata } from '../hooks/site-metadata.js'
import { graphql } from 'gatsby'

const App = props => {
    const meta = useSiteMetadata()

    //@Todo fixme, this min-h-screen is a hack. for some reason footer jumps up if less content
    //Layout grid problem??
    return (
        <Layout>
            <div className="sm:mt-24 min-h-screen">
                <Router basepath="/app">
                    <PrivateRoute
                        path="/my-courses"
                        component={MyCourses}
                        meta={meta}
                        data={props.data}
                    />
                </Router>
            </div>
        </Layout>
    )
}

export const query = graphql`
    query {
        allCourse {
            nodes {
                id
                title
                description
                url
                difficulty
                cardImg {
                    childImageSharp {
                        fixed(height:100) {
                            ...GatsbyImageSharpFixed_withWebp
                        }
                    }
                }
            }
        }
    }
`
export default App
