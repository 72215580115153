import React from 'react'
import { navigate } from 'gatsby'
import { useSession } from '../util/context.js'

const PrivateRoute = ({ component: Component, ...rest }) => {
    const { isLoading, user } = useSession()

    React.useEffect(()=>{
        if (!user && !isLoading && rest.location.pathname !== `/login`) {
            navigate('/login', {
                state: {
                    message: 'This page requires authentication, Please Sign In',
                    redirectTo: rest.location.pathname,
                },
                replace: true,
            })
        }
    },[isLoading, user, rest.location])


    if (isLoading) return null
    if (!isLoading && !user) return <h1>Logged Out...</h1>

    return <Component {...rest} />
}

export default PrivateRoute
